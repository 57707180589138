import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'
import { resolveTileLink } from '/machinery/link'
import { useLanguage } from '/machinery/I18n'

import { Tile } from '/features/buildingBlocks/tile/Tile'
import { SliderButtons } from '/features/buildingBlocks/SliderButtons'

import styles from './TileSlider.css'

export function TileSlider({ tiles }) {
  const isMounted = useRenderOnMount()
  const language = useLanguage()

  const slidesPerView = 1.2
  const sliderDisabled = slidesPerView >= tiles.length
  const showSliderButtons = !sliderDisabled && isMounted

  const [currentSlide, setCurrentSlide] = React.useState(0)

  const { sliderRef, instanceRef } = useKeenSliderRefs({
    handleSlideChange,
    slidesNum: tiles?.length,
    slidesPerView,
    spacing: 16,
    initialSlide: 0,
    sliderDisabled: slidesPerView >= tiles.length,
    origin: 0,
    interactionTitle: 'tile-slider'
  })

  return (
    <div className={styles.component}>
      <ul ref={sliderRef} className={cx(styles.slider, sliderDisabled && styles.sliderDisabled, 'keen-slider')}>
        {tiles?.filter(item => item.representation).slice(0, 4).map((item, i) => (
          <li
            data-context-tile-index={i}
            key={i}
            className={cx(styles.slideContainer, 'keen-slider__slide')}
          >
            <Tile
              representation={item.representation}
              href={resolveTileLink({ tile: item, language })}
            />
          </li>
        ))}
      </ul>
      {showSliderButtons && (
        <SliderButtons
          previousButtonDisabled={currentSlide === 0}
          nextButtonDisabled={currentSlide === (tiles?.length - Math.round(slidesPerView))}
          layoutClassName={styles.sliderButtonsLayout}
          {...{ currentSlide, handleClick }}
        />
      )}
    </div>
  )

  function handleClick(idx) {
    instanceRef.current?.moveToIdx(idx)
  }

  function handleSlideChange(idx) {
    setCurrentSlide(idx)
  }
}
